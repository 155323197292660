.About {
    color: white;
    margin: 0px 30px;
    p {
        font-size: x-large;
        font-style: italic;
    }
}

.col-md-10 {
    padding: 0px 10px 0px 20px;
}


@media (max-width: 767px) {
    .About {
        margin: 20px 0px 0px 0px;
        padding: 0px 20px;
        max-width: 97.5%;
    }    
}
