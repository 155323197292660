html,
body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.App {
  height: inherit;
  text-align: center;
  *:focus {
    outline: none;
  }
}

#particles canvas{
  position: absolute;
  width: 100vw !important;
  background-color: black;
}

@import "~bootstrap/scss/bootstrap";