@media (max-width: 767px) { 
    .Navbar{
        display: none;
    }
}

@media (min-width: 767px) {
    .SideNav {
        display: none;
    }
}

.Navbar {
    z-index: 1;
}