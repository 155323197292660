.Alert { 
    position: fixed;
    margin-top: 10px;
    z-index: 10000;
    left: 25%;
    width: 50%;
    top: 40px;
    display: block;
}

.ReCAPTCHA {
    div {
        div {
            margin: auto;
            padding-bottom: 10px;
        }
    }
}
