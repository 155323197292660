.Programs {
    margin: 50px 100px 0px 100px;
    max-width: 93.5%;
}

.col-md-12 {
    padding: 0px !important;
}

.column {
    background-color: gray;
    padding: 20px 0px;
    margin: auto;
    border-radius: 5px;
    height: 72vh;
    margin: 30px 30px 0px 30px;
    hr {
        border-top: 2px solid #222222;
        width: auto;
        margin: 10px 20px;
    }
    box-shadow: 10px 10px 2px #222222;
}

.InnerPic {
    width: 100%;
    height: auto;
}

h5 {
    padding: 0px 23px;
}

.OuterPic {
    text-align: center;
}

@media (max-width: 1420px) {
    .Programs {
        margin: 0px 10px !important;
    }    

    .column {
        height: auto;
    }
}
