.ContactForm {
    width: 85%;
    background-color: gray;
    padding: 20px 0px;
    margin: auto;
    border-radius: 5px;
    box-shadow: 10px 10px 2px #222222;
}

.Form {    
    padding: 0px 30px;
}

.Info {
    padding: 0px 30px;
}

.SocialMediaIcon {
    margin: 0px 15px;
}

.col-md-6 {
    padding: 0px !important;
}

hr {
    border-top: 2px solid #222222;
}

.form-group {
    text-align: left;
}

.SocialMediaIcons {
    padding-top: 20px;
}

h6 {
    padding-bottom: 10px;
}

a {
    color: black;
}

a:hover {
    text-decoration: none;
    color: white;
} 

@media (min-width: 767px) {
    .Form {
        border-left: 2px solid #222222;
    }

    .ContactForm {
        max-width: 1100px;
    }

    .Split {
        hr {
            display: none !important;
        }
    }
}

@media (max-width: 767px) {
    .ContactForm {
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .Split {
        hr {
            border-top: 2px solid #222222;;
            margin-top: 20px;
        }
    }
}