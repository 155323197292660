.VexU {
    color: white;
    p {
        font-size: x-large;
        font-style: italic;
    }
    margin-top: 100px;
}

.Team {
    border-radius: 5px;
    box-shadow: 10px 10px 2px #222222;
    width: 338px;
    height: 608px;
}

.TeamPic {
    text-align: center;
    padding: 20px;
}

.col-md-5 {
    padding: 0px !important;
}

.MainParagraph {
    text-align: center;
    width: 100%;
    padding: 0px 10px 0px 20px;
}

@media (max-width: 767px) { 
    .TeamPic {
        text-align: center;
        padding: 0px !important;
    }
    .VexU {
        margin-top: 10px;
        padding: 0px 10px;
        max-width: 98%;
        text-align: center;
        justify-content: center;
    }
}