.arrow,
.arrow:before {
  position: absolute;
  z-index: 5;
}

.arrow {
  left: 50%;
  width: 15px;
  height: 15px;
  top: 99%;
  margin: -30px 0 0 10px;
  -webkit-transform: rotate(45deg);
  border-left: none;
  border-top: none;
  border-right: 2px rgba(255, 255, 255, .5) solid;
  border-bottom: 2px rgba(255, 255, 255, .5) solid;
}

.arrow:before {
  content: "";
  width: 10px;
  height: 10px;
  left: 50%;
  top: 99%;
  margin: -11px 0 0 -5px;
  border-left: none;
  border-top: none;
  border-right: 1px rgba(255, 255, 255, .5) solid;
  border-bottom: 1px rgba(255, 255, 255, .5) solid;
  animation-duration: 1.7s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}

@keyframes arrow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-10px, -10px);
  }
}

.sponsors-div {
  position: absolute;
  bottom: 0vh;
  background-color: #444444;
  padding-top: 20px;
  padding-bottom: 50px;
  height: 190px;
  width: 100%;
}

.container-div { 
  display: flex;
  height: 100vh;
  width: 100%; 
  align-Items: center;
  justify-Content: center;
}

.logo-div {
  padding-bottom: 150px;
  padding-right: 100px;
}

.thankYou {
  width: auto;
  height: auto;
  bottom: 175px;
  background-color: #333333;
  color: white;
  border-radius: 5px;
  position: absolute;
  align-Items: center;
  justify-Content: center;
  padding: 3px 10px 2px 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #111111;
}

@media (max-width: 767px) {
  .logo-div {
    padding-right: 0px !important;
  }
}